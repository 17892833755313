<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车用户查询</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >车主姓名：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车主姓名"
            v-model="query.trueName"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >车主手机号：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入手机号"
            v-model="query.mobile"
            clearable
          />
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="userInfocx"
          >查询</el-button
        >
        <el-button size="small" @click="userInfocz()">重置</el-button>
      </div>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="trueName"
            label="车主姓名"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="mobile"
            label="车主手机"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="注册时间"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShowQueryDialog(scope)"
                size="mini"
                icon="el-icon-share"
                >查看用户车辆</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 查看用户车辆 -->
    <el-dialog
      :title="userName + '的车辆'"
      :visible.sync="isShowQuery"
      width="50%"
      v-el-drag-dialog
    >
      <el-table :data="tableDatatk" style="width: 100%">
        <el-table-column prop="id" label="编号" width="55"></el-table-column>
        <el-table-column prop="plateNo" label="车牌号"></el-table-column>
        <el-table-column prop="carType" label="车型">
          <template slot-scope="scope">
            <span v-if="scope.row.carType">{{
              findtranslate(typeCar, scope.row.carType)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="carColor" label="车牌颜色">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.carColor">{{
              findtranslate(typeColor, scope.row.carColor)
            }}</span>
          </template> -->
        </el-table-column>
        <el-table-column prop="carBrand" label="车品牌"></el-table-column>
        <el-table-column prop="createTime" label="注册时间" width="170">
          <template slot-scope="scope">
            <span>{{ conversionTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { userInfo, findUserCar } from '@/api/Parkinglot.js'
import { sysDictData } from '@/api/dictionaries.js'
export default {
  data() {
    return {
      loading: false,
      isShowData: false,
      isShowQuery: false,
      tableData: [],
      tableDatatk: [],
      query: {
        // 查询条件
        current: 1,
        size: 10,
        trueName: undefined, // 车主姓名
        mobile: undefined, // 车主手机号
      },
      delId: undefined,
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      userName: undefined, // 保存当前用户名字
      typeCar: [],
      typeColor: [],
      chargeType: [],
    }
  },
  created() {
    this.sysDictData()
  },
  methods: {
    userInfo() {
      // 进出记录查询列表
      userInfo(this.query).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        if (this.tableData) {
          this.isShowData = true
          this.query.trueName = undefined
          this.query.mobile = undefined
          this.query.endTimeBegin = undefined
          this.query.endTimeEnd = undefined
          this.query.kindId = undefined
          this.loading = false
        }
      })
    },
    userInfocx() {
      // 查询
      this.query.current = 1
      this.userInfo()
    },
    userInfocz() {
      // 重置
      this.query.trueName = ''
      this.query.mobile = ''
      this.userInfo()
    },
    isShowQueryDialog(scope) {
      this.tableDatatk = []
      // 显示查询用户车
      const mobile = {
        mobile: scope.row.mobile,
      }
      this.userName = scope.row.trueName
      findUserCar(mobile).then((res) => {
        this.tableDatatk = res.data.data
        this.isShowQuery = true
      })
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.userInfo()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.userInfo()
    },
    sysDictData() {
      // 字典
      sysDictData({ typeId: 40 }).then((res) => {
        // 车牌类型
        if (res.data.code === '200') {
          this.typeCar = res.data.data
          this.userInfo()
          sysDictData({ typeId: 80 }).then((res) => {
            // 收费类型固定/临时
            this.chargeType = res.data.data
          })
          sysDictData({ typeId: 81 }).then((res) => {
            // 车颜色
            this.typeColor = res.data.data
          })
        }
      })
    },
    findtranslate(data, id) {
      // 翻译字典
      if (id === null) {
        return
      }
      var ting = {}
      ting = data.find((item) => item.code === id)
      return ting.name
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
